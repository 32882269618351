<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
    VclList,
    Stat,
  },
  data() {
    return {
      premiations: null,
      statData: null,
    };
  },
  methods: {
    getPremiations() {
      api.get("report/premiations").then((response) => {
        if (response.data.status == "success") {
          this.premiations = response.data.list;

          this.statData = [
            {
              icon: "bx bx-dollar",
              title: "Total de Pontos",
              value: this.$options.filters.points(
                response.data.total
              ),
            },
          ];
        }
      });
    },
  },
  mounted() {
    this.getPremiations();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">Premiações</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6">
        <Stat
          :icon="stat.icon"
          :title="stat.title"
          :value="stat.value"
        />
      </div>
    </div>

    <vcl-list v-if="!premiations" class="col-lg-6 card card-body"></vcl-list>
    <div v-else class="card">
      <div class="card-body pt-4 px-4 pb-1">
        <div v-for="(row, index) in premiations" :key="index" class="d-md-flex align-items-center">
          <div class="premiation-image mb-4 text-center">
            <img
              v-if="row.image"
              :src="require('@/assets/images/premiations/' + row.image)"
              class="img-fluid rounded"
               style=""
            />
          </div>
          <div class="premiation-info flex-fill mb-4">
              <h5 class="mb-0 text-dark">{{ row.name }}</h5>
              <h4 class="mb-0 text-dark">
                {{ row.points | points }}
              </h4>
              <p class="m-0"> {{ row.vme | points }}</p>
            <b-progress
              style="border-radius: 3px"
              :value="Number(row.percent)"
              striped
              variant="warning"
              height="20px"
            ></b-progress>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.premiation-image img {
  width:300px;
  height:200px;
  object-fit: cover;
}
.premiation-info {
  padding-left: 20px;
}

@media (max-width: 575px) {
  .premiation-image img {
    width: 100%;
  }
  .premiation-info {
    padding-left: 0;
  }
}
</style>
